/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SectionContainer from '../components/SectionContainer';

const Conditions = props => (
  <Layout location={props.location}>
    <div className="conditions-page">
      <SectionContainer num={1} container>
        <h2>Terms & Conditions</h2>
        <div className="content-block">
          <p>
            The following terms of use (the "Terms of Use") govern your use (the
            term "use" will mean access or use, as applicable) of: (1) Inkblot
            Technologies Inc. ("Inkblot", "us" or "we") website located at
            www.inkblottherapy.com (the "Website"); and (2) all other services
            provided by Inkblot, as described on the Website ((1) through (2)
            collectively, the "Platform"). These Terms of Use form an agreement
            between Inkblot and you. The term "you" refers to the person or
            entity visiting the Platform, browsing or otherwise using the
            Platform, or receiving any Services (as hereafter defined). A copy
            of these Terms of Use may be downloaded, saved and printed for your
            reference.
            <br />
            <br /> BY ACCESSING AND USING THE PLATFORM, YOU ACCEPT AND AGREE TO
            BE BOUND BY AND COMPLY WITH THESE TERMS OF USE. IF YOU DO NOT ACCEPT
            AND AGREE TO BE BOUND BY THESE TERMS OF USE, PLEASE DO NOT ACCESS OR
            USE THE PLATFORM. <br />
            <br />
            DO NOT USE THE PLATFORM FOR MEDICAL EMERGENCIES OR IF YOU ARE HAVING
            SUICIDAL THOUGHTS. IF YOU HAVE A MEDICAL EMERGENCY OR ARE
            CONTEMPLATING HARMING YOURSELF OR OTHERS, YOU SHOULD DISCONTINUE
            USING THE PLATFORM AND CALL 911 IMMEDIATELY OR ATTEND THE EMERGENCY
            ROOM OF THE NEAREST HOSPITAL OR HEALTH CENTRE. UNDER NO
            CIRCUMSTANCES SHOULD YOU ATTEMPT SELF-TREATMENT BASED ON ANY CONTENT
            (AS HEREAFTER DEFINED).
          </p>
        </div>
        <div className="title">
          <p>1. Changes to these Terms of Use and Services</p>
        </div>

        <div className="content-block">
          <p>
            EXCEPT WHERE PROHIBITED BY APPLICABLE LAW, INKBLOT RESERVES THE
            RIGHT TO CHANGE THESE TERMS OF USE AT ANY TIME WITHOUT NOTICE. YOUR
            CONTINUED ACCESS TO OR USE OF THE PLATFORM AFTER ANY CHANGES TO
            THESE TERMS OF USE INDICATES YOUR ACCEPTANCE OF SUCH CHANGES. IT IS
            YOUR RESPONSIBILITY TO REVIEW THESE TERMS OF USE REGULARLY.
            <br />
            <br />
            INKBLOT RESERVES THE RIGHT TO CHANGE ANY INFORMATION, MATERIAL OR
            CONTENT (INCLUDING, BUT NOT LIMITED TO, PRICING, FEATURES, THE "LOOK
            AND FEEL" OF THE WEBSITE AND AVAILABILITY OF SERVICES) CONTAINED ON
            OR PROVIDED THROUGH THE PLATFORM (THE "CONTENT") AT ANY TIME, AND
            FROM TIME TO TIME, WITHOUT NOTICE.
          </p>
        </div>
        <div className="title">
          <p>2. Use of Services</p>
        </div>
        <div className="content-block">
          <p>
            AS A CONDITION OF YOUR USE OF THE PLATFORM, YOU WARRANT THAT: (1)
            YOU HAVE REACHED THE AGE OF MAJORITY IN YOUR JURISDICTION OF
            RESIDENCE; (2) YOU POSSESS THE LEGAL AUTHORITY TO CREATE A BINDING
            LEGAL OBLIGATION; (3) YOU WILL USE THE PLATFORM IN ACCORDANCE WITH
            THESE TERMS OF USE; (4) ALL INFORMATION SUPPLIED BY YOU ON THE
            PLATFORM IS TRUE, ACCURATE, CURRENT AND COMPLETE; AND (5) IF YOU ARE
            ACCESSING OR USING THE PLATFORM ON BEHALF OF ANOTHER PERSON OR A
            CORPORATE ENTITY OR OTHER NON-INDIVIDUAL, YOU REPRESENT AND WARRANT
            THAT YOU HAVE THE AUTHORITY TO BIND SUCH PERSON OR ENTITY TO THESE
            TERMS OF USE.
            <br />
            <br />
            The Platform is provided solely (the "Permitted Use") to: (1) assist
            you in gathering information about the various services offered by
            Inkblot through the Platform; (2) if and as permitted by Inkblot and
            these Terms of Use, enable you to use the Platform and receive any
            services offered to you through the Platform, including counselling
            services; (3) if applicable, facilitate any payments from you to
            Inkblot in respect of your use of the Platform or the receipt of any
            services through the Platform; and (4) if and as permitted by
            Inkblot and these Terms of Use, provide feedback and otherwise
            communicate with Inkblot in connection with any of the foregoing
            ((1) through (4) collectively, "Services").
            <br />
            <br />
            The Platform is only intended to assist in addressing those mental
            health problems listed on our website. Inkblot matches Counsellors
            (as hereafter defined) with you based on the information provided by
            you and by them. These Counsellors are only suggestions and Inkblot
            does not recommend any Counsellors or guarantee their ability to
            assist you.
            <br />
            <br /> You are responsible for the conduct of any session with a
            Counsellor and the provision of any treatment therein. Inkblot is
            not liable or responsible for any harm that arises out of or relates
            to a session you participate in. If you have a complaint against a
            Counsellor, you can email us at support@inkblottherapy.com and
            identify the relevant Counsellor. We will direct you to the
            applicable regulatory authority to assist you in filing your
            complaint. Alternatively, you can also contact the professional
            regulatory college(s) in the province(s) where the Mental Health
            Services (as hereafter defined) were received. <br />
            <br /> In a professional relationship, sexual intimacy is never
            appropriate and should be reported to the professional regulatory
            college(s) that licenses, registers, or certifies the licensee.{' '}
            <br />
            <br /> The duration of a session shall be presented to you at the
            time of booking. In scheduling your sessions, you must also comply
            with our termination policy set out in Section 7 of these Terms of
            Use, as such policy may be amended by us from time to time. You may
            end your relationship with a Counsellor at any time, subject to our
            termination policy. You agree that you may interact with your
            Counsellor through the Platform and in person. A Counsellor is
            obligated to inform us if you wish to visit them in person or
            through other means. You expressly acknowledge and consent to the
            disclosure by the Counsellor to us of such information. <br />
            <br /> Inkblot does not mandate any specific treatment method nor do
            we recommend any procedure over another. This decision is solely
            based on the discretion of your Counsellor. <br />
            <br /> If you and your Counsellors decide to engage in any couple or
            group therapy, you agree that that the information discussed in the
            applicable group sessions are confidential and shall not be utilized
            by you in any legal proceedings. You agree not to involve any
            Counsellors, other Users of the Platform, or Inkblot to testify in
            any court for any of the parties involved in the group therapy.{' '}
            <br />
            <br />
            You agree that you will not use the Services for any unlawful
            purpose or in any way that interrupts, damages, impairs or renders
            the Services less efficient or inefficient; use the Services to
            transfer files that contain viruses, trojans, logic bombs, worms,
            corrupted data or other harmful programs or malicious software;
            access or attempt to access the accounts of other users of the
            Services; to impersonate any person or entity, or otherwise
            misrepresent your affiliation with a person or entity; penetrate or
            attempt to penetrate any security measures; to conduct or promote
            any illegal activities; to upload, distribute or print anything that
            may be harmful to minors; to disseminate any content which is
            defamatory, false or inaccurate or becomes false or inaccurate at
            any time, obscene, indecent, seditious, offensive, pornographic,
            abusive, discriminatory, menacing, scandalous, inflammatory, in
            breach of confidence, in breach of privacy, or may have the effect
            of being harassing, threatening, or abusive to an individual or
            group of individuals on the basis of religion, gender, sexual
            orientation, race, ethnicity, age or disability or otherwise; to
            post or transmit material for which you have not obtained all
            necessary licenses and/or approvals; to misrepresent your identity
            in any way; to post or transmit material which constitutes or
            encourages conduct that would be considered a criminal offense,
            gives rise to civil liability, or otherwise be contrary to the law
            or infringe the rights of any third party; to advertise or promote
            third party or your own products or services; to generate spam,
            unsolicited e-mail advertisements, junk mail, chain letters, or
            otherwise duplicative messages; to stalk, harass, harm or threaten
            harm on another individual; engage in any conduct that restricts or
            inhibits anyone’s use or enjoyment of the Services; license, sell,
            rent, lease, transfer, assign, distribute, host, outsource, disclose
            or otherwise commercially exploit the Services or make the Services
            available to any unauthorized third party; modify, make derivative
            works of, disassemble, reverse compile or reverse engineer any part
            of the Services; or access the Services in order to build a similar
            or competitive product or service.
            <br />
            <br /> You agree to refrain from doing anything which is fraudulent,
            negligent or which we reasonably believe to be disreputable or
            capable of damaging Inkblot’s reputation. <br />
            <br /> You acknowledge that we have limited control over the nature
            and content of information transmitted or received by you or other
            users of the Platform and Services including, but not limited to,
            any content provided by Counsellors. Although we reserve the right
            to do so, we do not monitor such content in the usual course of
            business and will not be liable for any such content. If you have a
            complaint about another user or a Counsellor please contact us at
            support@inkblottherapy.com. <br />
            <br />
            Inkblot retains the right, at its sole discretion, to deny access to
            anyone to the Platform or the Services, at any time and for any
            reason, including, but not limited to, for violation of these Terms
            of Use. You will cease and desist from any such access or use
            immediately upon request by Inkblot.
          </p>
        </div>
        <div className="title">
          <p>3. Counselling Services</p>
        </div>
        <div className="content-block">
          <p>
            The Services, as permitted by Inkblot and these Terms of Use, enable
            you to use the Platform to obtain on-demand access to mental health
            counsellors ("Counsellors") and support staff ("Support Staff") by
            secure text and video for mental health consultations, and to
            receive related administrative support services via online
            communication tools (as a subset of the Services, "Mental Health
            Services" and "Support Services", respectively). All Counsellors are
            independent of Inkblot and are solely responsible for the provision
            of Mental Health Services to you. All Support Staff are employees of
            Inkblot and only provide Support Services that do not fall within
            the scope of practice of any regulated health profession. Inkblot is
            not engaged in the practice of medicine or any other regulated
            health profession. Neither Inkblot nor any other party associated
            with Inkblot is liable for any Mental Health Services provided to
            you by Counsellors. None of the Content is medical advice or an
            endorsement, representation or warranty regarding the safety,
            appropriateness or effectiveness of any particular treatment or
            medication for you.
            <br />
            <br /> FOR GREATER CLARITY, INKBLOT IS NOT A PROVIDER OF
            TELEMEDICINE OR TELEMEDICINE SERVICES. INKBLOT DOES NOT PROVIDE
            HEALTH CARE SERVICES, MENTAL HEALTH SERVICES OR SOCIAL OR
            PSYCHOTHERAPY SERVICES, OR ANY OTHER PROFESSIONAL SERVICES. Inkblot
            is simply a technology service provider that facilitates the
            Services through the Platform. <br />
            <br /> Your medical care and your mental health care are solely the
            responsibility of you and your Counsellor(s). <br />
            <br />
            While the Platform and the Services may provide access to certain
            general medical information, the Platform and the Services cannot
            and are not intended to provide medical advice. We advise you to
            always seek the advice of a physician or other regulated health
            professional with any questions regarding your personal health or
            medical conditions. Never disregard, avoid, or delay in obtaining
            medical advice from your physician or other regulated health
            professional because of something you have read on the Platform. If
            you have or suspect that you have a medical problem or condition,
            please contact a physician or other regulated health professional
            immediately.
            <br />
            <br /> COUNSELLORS ARE NOT THE EMPLOYEES OR AGENTS OF INKBLOT AND
            ARE NOT CREDENTIALED BY INKBLOT. INKBLOT DOES NOT HAVE ANY OVERSIGHT
            OF THE CARE PROVIDED TO YOU BY COUNSELLORS. Each Counsellor is an
            independent practitioner who is solely responsible for any Mental
            Health Services provided by them to you. <br />
            <br /> Under the Services, mental health care services or social or
            psychotherapy services are provided exclusively by Counsellors.
            Inkblot supports Counsellors by providing them with a license to use
            our intellectual property rights. However, exclusive control and
            responsibility for the delivery of mental health care services or
            social or psychotherapy services is reserved to Counsellors. <br />
            <br /> All Counsellors available through the Platform self-identify
            the areas in which they are competent and represent that they are
            permitted to provide services in such areas and are a member in good
            standing with the regulatory college of their jurisdiction. Inkblot
            requires Counsellors to represent and warrant that they are in good
            standing with their respective regulatory college and to immediately
            apprise Inkblot of any changes to such status. Although Inkblot vets
            Counsellors, we have no means to guarantee that such Counsellors
            specialize in their claimed fields. While we do our best to ensure
            the qualifications of Counsellors who deliver Mental Health Services
            on the Platform, you agree that Inkblot shall not be liable for the
            quality of the service provided by Providers. <br />
            <br /> Unless otherwise agreed by Inkblot, the Services described in
            these Terms of Use are solely offered within Canada.
          </p>
        </div>
        <div className="title">
          <p>
            4. Consent to Counselling and Collection, Use and Disclosure of
            Personal Information
          </p>
        </div>
        <div className="content-block">
          <p>
            By accessing and using the Platform to receive Services via the use
            of online communication tools such as secure text and video, you
            consent to be consulted by one or more Counsellors or Support Staff.
            Counsellors may ask you questions about your personal health
            history, present condition or symptoms, and any course of treatment
            or medication that has been prescribed to you. Support Staff only
            provide Support Services that are informational in nature. You may
            withdraw your consent to receive Mental Health Services or Support
            Services at any time by ending your communication with the
            Counsellor or Support Staff.
            <br />
            <br />
            Neither the Mental Health Services provided by Counsellors nor the
            Support Services provided by Support Staff are intended to replace
            your primary care relationship with your family physician or other
            primary care health professional. You should continue to consult
            your family physician or other primary care health professional
            regularly or as needed or recommended by them. <br />
            <br />
            Counsellors will make every effort to keep your personal information
            private. If you wish to have information released, you will be
            required to sign a consent form before such information will be
            released. There are some limitations to confidentiality to which you
            need to be aware. Your counsellor may consult with a supervisor or
            other professional counsellor in order to give you the best possible
            service. In the event that a counsellor consults with another
            counsellor, no identifying information such as name would be
            released. Counsellors are required by law to release information
            when the client poses a risk to themselves or others and in cases of
            abuse to children or the elderly. If the Counsellor receives a court
            order or subpoena, the Counsellor may be required to release some
            information. In such a case, the counsellor will consult with other
            professionals and limit the release to only what is necessary by
            law.
            <br />
            <br />
            You are encouraged to ask questions of the Counsellors regarding any
            part of the session that is conducted or recommended to you, all of
            which should be answered to your satisfaction. If you are not
            satisfied with the explanation or information provided by the
            Counsellors regarding any session that is conducted or recommended
            for you, you should not continue it or you should seek a second
            opinion from a health professional that is not affiliated with
            Inkblot. You have the right to refuse or withdraw consent at any
            time.
            <br />
            <br /> In addition to the material risks that may be associated with
            a counselling session, there are potential material risks that are
            specifically associated with using online communication tools and
            which may adversely impact the ability of the Counsellors and
            Support Staff to provide Services, including but not limited to (a)
            poor quality audio-video connections due to remote locations or
            other network limitations that may limit the ability of the
            Counsellors to appropriately conduct the session or otherwise obtain
            sufficient personal information from you to properly conduct the
            session, (b) delays in evaluation or treatment resulting from
            similar technology failures, (c) lack of access to your personal
            health records maintained outside the Platform (i.e., by your family
            physician or other primary care health professional), which may
            result in judgment errors, and (d) unauthorized collection, use or
            disclosure of your personal information due to a security protocol
            breach or failure.
            <br />
            <br /> INKBLOT SHALL NOT HAVE ANY LIABILITY ARISING FROM OR IN ANY
            WAY RELATED TO YOUR INTERACTION OR RELATIONSHIP WITH COUNSELLORS
            EXCEPT AS MAY BE EXPLICITLY PROVIDED HEREIN.
            <br />
            <br />
            You acknowledge that Inkblot is only responsible for providing the
            software required for the transmission of personal information
            through the Platform and, in light of the foregoing, Inkblot cannot
            be responsible for other purposes for which the Platform is used by
            Counsellors, nor for the privacy practices of Counsellors. You
            acknowledge and agree that Inkblot is not responsible for a breach
            of your personal information if you use the Platform in a public or
            unsecure environment where a session may be viewed or overheard
            (e.g. coffee shop, Internet café) and such personal information is
            overheard by others in your vicinity.
            <br />
            <br /> You acknowledge and agree that Inkblot is not responsible and
            shall not be liable for the disclosure of your personal information
            and confidential data by the Counsellors. You acknowledge and agree
            that we are not responsible for the behavior, actions or inactions
            of Counsellors.
            <br />
            <br /> Inkblot disclaims any and all liability related to your
            interaction with Counsellors whether or not such interaction occurs
            on the Platform or elsewhere.
            <br />
            <br />
            By consenting to receive Services using the Platform, you consent to
            these inherent material risks. By providing or uploading any of your
            personal information to the Platform, Counsellors or Support Staff,
            you are consenting to the collection, use and disclosure of your
            personal information for the purposes of providing Mental Health
            Services and Support Services to you and for purposes that are
            consistent with those purposes. Only the Counsellors and Support
            Staff will have access to your personal information and neither
            Inkblot nor any other party associated with Inkblot will disclose
            your personal information to any third party except as may be
            required by law or as set forth in our Privacy Policy. Your personal
            information will be securely and digitally stored on servers
            physically located in Canada. Although Inkblot uses advanced
            encryption technology and other security protocols to protect your
            personal information and the privacy of your online consultations
            with Counsellors and Support Staff, you acknowledge by using the
            Platform that there are inherent risks to any technology, however
            remote, that could cause security protocols to fail or be breached,
            which could result in the unauthorized collection, use or disclosure
            of your personal information. Inkblot will notify you to the extent
            required by law of any security protocol failure or breach that has
            resulted in the unauthorized collection, use or disclosure of your
            personal information. We will not be responsible for any damages you
            or any third party may suffer as a result of the transmission of
            confidential information that you make available to us through the
            Internet, or that you expressly or implicitly authorize us to make,
            or for any errors or any changes made to any transmitted
            information.
            <br />
            <br /> For further information regarding Inkblot’s privacy policy,
            please see the section below entitled &quot;Privacy Policy&quot; and
            the embedded link to Inkblot’s Privacy Policy. The terms of the
            Privacy Policy are incorporated into, and considered a part of, this
            agreement.
            <br />
            <br /> We may disclose your identity to any third party who claims
            that material posted by you violates their rights, including their
            intellectual property rights or their right to privacy. You consent
            to such disclosure of information without further notice to you.
          </p>
        </div>
        <div className="title">
          <p>5. License Grants</p>
        </div>
        <div className="content-block">
          <p>
            Subject to these Terms of Use, Inkblot grants you a personal,
            revocable, non-exclusive and non- transferable license during the
            Term to permit you to use the Platform, in accordance with these
            Terms of Use.
            <br />
            <br /> Subject to these Terms of Use, you grant to Inkblot a
            perpetual, transferrable, irrevocable, royalty-free, fully paid-up,
            worldwide and fully sub-licensable license to access, collect, store
            and use any data, information, records and files that: (1) you load,
            transmit to or enter into the Platform, or (2) Inkblot or the
            Platform collects, retrieves or obtains from your local computer
            system or from third parties with your permission or on your behalf,
            and (in each case) including all results from processing such data,
            including compilations and derivative works thereof (collectively,
            the "User Content") including for the purpose of: (A) providing the
            Services, (B) complying with applicable law, and (C) Inkblot’s
            reasonable audit and data retention policies.{' '}
          </p>
        </div>
        <div className="title">
          <p>6. User Account</p>
        </div>
        <div className="content-block">
          <p>
            You may be required to successfully sign up for a user account (the
            "User Account") using the available interfaces on the Platform and
            be provided with a username and password login credentials by
            Inkblot ("User ID") in order to use the Platform and receive any
            Services. If you are provided with a User ID, you will keep your
            User ID secure and will not share your User ID with anyone else, and
            you will not collect or harvest any personal data of any other user
            of Inkblot, including account names or login credentials. Inkblot
            reserves the right to disable any User Account issued to you at any
            time in Inkblot’s sole discretion. If Inkblot disables access to a
            User Account issued to you, you may be prevented from accessing the
            Platform, the Content, your account details and/or any User Content.
            <br />
            <br />
            You agree to provide true, current, complete and accurate
            information as requested on any registration form to which the
            Platform may direct you, and to update that registration information
            as soon as possible after any information on such registration form
            changes.
            <br />
            <br /> The Services are also available for use by children at the
            discretion of the Counsellors or Support Staff, but the User Account
            and User ID, for all patients under the age of 15 ("minors") must be
            the patient’s parent or legal guardian and must be present at all
            sessions. If you register as the parent or legal guardian on behalf
            of a minor, you are fully responsible for complying with these Terms
            of Use. The Terms of Use set out above under "Consent to
            Counselling, Use and Disclosure of Personal Information" apply to
            minors who access the Platform and receive Services with necessary
            changes to points of detail. Minors may only use the Platform and/or
            receive Services in the presence of their parent or legal guardian.{' '}
          </p>
        </div>
        <div className="title">
          <p>7. Term and Termination</p>
        </div>
        <div className="content-block">
          <p>
            These Terms of Use will commence on the day you first use the
            Platform and will continue in force until terminated by either party
            as described below (the "Term"). Either party may terminate these
            Terms of Use as follows: (1) Inkblot may terminate these Terms of
            Use at any time and with immediate effect by giving notice to you,
            at Inkblot’s discretion, by email (at your current email address on
            file with Inkblot) or through the Platform; and (2) you may
            terminate these Terms of Use at any time and with immediate effect
            by requesting (by email or through any then-available interfaces on
            the Platform) that your User Account be deleted or deactivated,
            ceasing use of the Platform and uninstalling and removing all local
            software components thereof from your systems.
            <br />
            <br />
            We are not liable to you or any other party for any termination of
            your User Account or access to the Platform. Termination of your
            User Account will not modify, change or void any payment obligations
            you may have incurred through your use of the Platform, whether such
            obligations are to us, a Counsellor or a third party.
            <br />
            <br /> We reserve the right (but have no obligation under these
            Terms of Use) to investigate and/or take appropriate action,
            including legal action, in our sole discretion against you if you
            violate any Terms of Use, including suspending or terminating your
            User Account and/or reporting you to law enforcement authorities.{' '}
            <br />
            <br /> We have the right to fully cooperate with any law enforcement
            authorities or court order requesting or directing us to disclose
            the identity of anyone posting any materials on or through the
            Platform. You waive and hold harmless Inkblot from any claims
            resulting from any actions taken by Inkblot during or as a result of
            its investigations and from any actions taken as a consequence of
            investigations by either Inkblot or law enforcement authorities.{' '}
          </p>
        </div>
        <div className="title">
          <p>8. Cancellation and Refund Policy</p>
        </div>
        <div className="content-block">
          <p>
            By booking a session through the Platform, you agree that you will
            be responsible for the full amount of any missed session fee as
            described in our cancellation policy. <br />
            <br />
            We require at least twelve hours notice prior to cancelling your
            appointment. You will lose the cost of a half-hour session every
            time you cancel late or miss an appointment.
            <br />
            <br />
            If the Counsellor is more than 15 minutes late for a session, you
            have the right to request a full refund for the scheduled service.
            You agree that such refund will only occur if you terminate the
            session and that by proceeding with a late session you agree to
            forego any refund.
            <br />
            <br />
            In the event of an emergency requiring late cancellation of a
            session, please email us at{' '}
            <a href="mailto:support@inkblottherapy.com">
              support@inkblottherapy.com
            </a>{' '}
            with the subject “Emergency Cancellation”.
          </p>
        </div>
        <div className="title">
          <p>9. Fees and Taxes</p>
        </div>
        <div className="content-block">
          <p>
            Inkblot may, in its sole discretion, offer some of the Services to
            you for free or charge fees in accordance with its then-current fee
            schedule that can be found on the Website and is subject to change,
            from time to time (the "Fee Schedule"). You or a responsible third
            party will pay any such applicable fees in accordance with the Fee
            Schedule (the "Fees"). Unless otherwise expressly indicated in the
            Fee Schedule, all Fees are quoted in Canadian dollars exclusive of
            applicable sales and value added taxes and are non-refundable and
            payable upon invoicing or demand from Inkblot. You or a responsible
            third party will pay all sales and value added taxes that may be
            imposed in each applicable jurisdiction and will hold and save
            Inkblot harmless in respect of any such taxes.
            <br />
            <br />
            If a third party (e.g., such as an employer or supplemental health
            insurance provider) has arranged with Inkblot to pay the fee or any
            portion of the fee, or if the fee is pursuant to some other
            arrangement with Inkblot, that fee adjustment will be reflected in
            the fee that you are ultimately charged. If you have a Health
            Spending Account ("HSA") offered as a benefit by your employer, you
            agree to pay all fees or charges to your HAS Account in accordance
            with the fees, charges, and billing terms in effect at the time a
            fee or charge is due and payable. Please check with your employer to
            determine if any Services will be reimbursed other than from your
            HSA. Inkblot reserves the right to modify or implement a new pricing
            structure at any time prior to billing you or a responsible third
            party for your initial payment or for future payments due pursuant
            to these Terms of Use. Inkblot may require you to provide and keep
            updated during the Term the details of a current and valid credit
            card, debit card, PayPal account, Apple Pay, or other payment method
            ("Payment Method") that is acceptable to Inkblot in its sole
            discretion. You hereby authorize Inkblot to charge any and all Fees
            that become due and payable to the Payment Method. You agree to pay
            such fees, charges and penalties (including costs and expenses
            related to collection by Inkblot of overdue payments) as Inkblot may
            from time to time set out on the Platform in respect of late or
            overdue payments, or declined or unsuccessful Payment Method
            transactions.
            <br />
            <br /> In engaging our Services you may be sent text message(s),
            e-mails and/or push notifications which provide you with important
            and useful information regarding our Services. You will be charged
            your mobile telephone network provider’s standard rate for receiving
            these SMS messages and standard wireless and data charges will apply
            to any e-mails or push notifications. Inkblot does not charge you
            for this correspondence. Your mobile telephone network provider may
            impose message, standard wireless and/or data charges or charge
            limitations which are beyond our control and for which you will be
            solely responsible.
            <br />
            <br /> By engaging our Services, including registering for a User
            Account, you agree to receive e-mails and push notifications
            relating to our Services. If you consent during registration, you
            also agree to receive text messages.
            <br />
            <br /> You warrant that you are the owner of the mobile telephone to
            which you have requested the SMS message(s), e-mails and push
            notifications should be sent to and that you have full authority to
            authorize such charges and will be solely responsible for charges,
            if any, to be incurred by the relevant mobile telephone network
            provider.
            <br />
            <br /> We are not liable for any delays or failures in your receipt
            of any SMS message(s), e-mails or push notifications as delivery is
            subject to effective transmission from your mobile telephone network
            provider and processing by your mobile telephone device.
            <br />
            <br /> We reserve the right to suspend or terminate the SMS service
            without notice to you.
          </p>
        </div>
        <div className="title">
          <p>10. Submissions</p>
        </div>
        <div className="content-block">
          <p>
            Other than personally identifiable information, which is covered
            under our Privacy Policy, any material you post, submit, publish,
            display or transmit concerning the Services and through the Platform
            shall be considered non-confidential and non-proprietary. You
            represent and warrant that such material complies with these Terms
            of Use, and you agree to defend, indemnify and hold harmless Inkblot
            and its affiliates and licensors for any breach of that
            representation and warranty. You acknowledge and understand that you
            are responsible for any such material, and you, not Inkblot, have
            full responsibility for such content, including its legality,
            reliability, accuracy and appropriateness. Inkblot shall have no
            obligations with respect to such material. Inkblot and its designees
            shall be free to copy, disseminate, disclose, distribute, publicly
            display, reproduce, use, sublicense, post, publish, incorporate and
            otherwise use such material and all data, images, sounds, text and
            other things embodied therein for any and all commercial or
            non-commercial purposes, without compensation to you. In the event
            that you provide us with any ideas, thoughts, criticisms, suggested
            improvements or other feedback (collectively, &quot;Feedback&quot;),
            you agree that we may use the Feedback to modify our Services and
            Platform and that you will not be due any compensation, including
            without limitation any royalty related to the product or service
            that incorporates your Feedback. You grant to us a worldwide,
            royalty-free, fully paid, perpetual, irrevocable license to use,
            reproduce, modify, translate, distribute, perform, display, import,
            sell, offer for sale, make, have made and otherwise exploit the
            Feedback in any form, media or technology, whether now known or
            hereafter developed, and to allow others to do the same. This is
            true whether you provide the Feedback on the Website or through
            other methods of communication with us, unless we have entered into
            a separate agreement with you that provides otherwise
          </p>
        </div>
        <div className="title">
          <p>11. Ownership</p>
        </div>
        <div className="content-block">
          <p>
            All Content, including, without limitation, all designs,
            infrastructure, graphics, pictures, illustrations, software,
            artwork, video, music, sound, names, words, titles, phrases, logos
            and marks displayed on the Platform are owned or licensed by Inkblot
            and are protected by copyright, trade-mark and other intellectual
            property laws.
            <br />
            <br /> Your use of Inkblot, the Platform and Services grants you no
            rights with respect to our intellectual property rights (including,
            without limitation, copyright, trademarks, trade names (including
            &quot;Inkblot&quot;, etc.), logos, graphics, photographs,
            animations, videos and text or rights in and to the Inkblot
            software, applications and the Platform) or the intellectual
            property of our retail or advertising partners, other than the
            non-transferable, personal right to use and receive the Services in
            accordance with these Terms of Use. The Content on the Platform and
            Services, including the text, software, scripts, graphics, photos,
            sounds, music, videos and interactive features and the trademarks,
            trade names, service marks and logos contained therein
            (collectively, &quot;Marks&quot;), are owned by or licensed to
            Inkblot, subject to copyright and other intellectual property rights
            under Canadian law and international conventions. Content on the
            Platform and Services is provided to you as is for your information
            and personal use only and may not be used, copied, reproduced,
            distributed, transmitted, broadcast, displayed, sold, licensed or
            otherwise exploited for any other purposes whatsoever without prior
            written consent and/or payment of the applicable licence fee to the
            owners.
            <br />
            <br /> Inkblot expressly reserves all rights in the Platform, the
            Services and all materials provided by Inkblot in connection with
            these Terms of Use that are not specifically and expressly granted
            to you. You acknowledge that all right, title and interest in the
            Platform, all materials provided by Inkblot in connection with these
            Terms of Use (including the Content), and any update, adaptation,
            translation, customization or derivative work thereof, and all
            intellectual property rights therein, will remain with Inkblot (or
            third party suppliers, if applicable), and that the Platform and
            Services and all materials provided by Inkblot hereunder are
            licensed, and not sold, to you. You agree not to engage in the use,
            copying, or distribution of any content other than expressly
            permitted herein. You agree not to circumvent, disable or otherwise
            interfere with security-related features of the Platform and
            Services or features that prevent or restrict use or copying of any
            content or enforce limitations on use of the Platform and Services
            or the Content therein.
          </p>
        </div>
        <div className="title">
          <p>12. Privacy Policy</p>
        </div>
        <div className="content-block">
          <p>
            Please take time to review our current Privacy Policy, available
            here, which contains important information about our practices in
            collecting, storing, using and disclosing your personal information,
            and which is hereby incorporated into and forms a part of these
            Terms of Use.
          </p>
        </div>
        <div className="title">
          <p>13. No Unlawful or Prohibited Use</p>
        </div>
        <div className="content-block">
          <p>
            You will not, without Inkblot’s prior written permission, use the
            Platform, the Services and/or the Content for purposes other than
            the Permitted Use. Without limiting the generality of the foregoing,
            you will not, and will not permit anyone else to: (1) frame, mirror
            or otherwise incorporate the Platform, the Services and the Content
            or any part thereof on any commercial or non-commercial website; (2)
            access, monitor, record or copy any part of the Platform, the
            Services and the Content including, but not limited to, any sessions
            with Counsellors, using any robot, spider, scraper or other
            automated means or any manual process for any purpose without our
            express written permission; (3) violate the restrictions in any
            robot exclusion headers on the Platform, the Services and the
            Content or bypass or circumvent other measures employed to prevent
            or limit access to the Platform; (4) take any action that imposes,
            or may impose, in Inkblot’s sole and absolute discretion, an
            unreasonable or disproportionately large load on the Platform; (5)
            deep-link to any portion of the Platform for any purpose; (6) remove
            (or permit anyone else to remove) any watermarks, labels or other
            legal or proprietary notices included in the Platform, the Services
            and the Content; (7) modify or attempt to modify (or permit anyone
            else to modify or attempt to modify) the Platform, the Services and
            the Content, including any modification for the purpose of
            disguising or changing any indications of the ownership or source of
            the Platform, the Services and the Content; (8) use the Platform,
            the Services and the Content as part of any service for sharing,
            lending or multi-person use, or for the purpose of any other
            institution, except as specifically permitted by Inkblot and only in
            the exact manner specified and enabled by Inkblot; (9) attempt to,
            assist, authorise or encourage others to circumvent, disable or
            defeat any of the security features or components, such as digital
            rights management software or encryption, that protect the Platform,
            the Services and the Content; (10) copy, reproduce, modify,
            distribute, transfer, sell, publish, broadcast, perform, transmit,
            publish, license or circulate in any form any part of the Platform,
            the Services and the Content; (11) create derivative works based on
            the Platform, the Services and the Content, in whole or in part, or
            to decompile, disassemble, reverse engineer or other exploit any
            part of the Platform or the Content; (12) use the Platform, the
            Services and the Content in a manner that violates the rights
            (including to intellectual property rights) of any third party,
            including by providing, uploading or transmitting any User the
            Platform, the Services, the Content or Feedback that violate such
            third-party rights; (13) upload to or transmit through the Platform
            any User Content or Feedback that is offensive, hateful, obscene,
            defamatory or violates any applicable laws, in each case as
            determined by Inkblot in its sole discretion; (14) use any recording
            device or software when using the Platform, the Services and the
            Content; and (15) attempt to impose any charges associated with
            bandwidth or data in using the Platform, the Services and the
            Content onto Inkblot.
            <br />
            <br /> You may download, print and reproduce the Content for your
            own non-commercial, informational purposes provided you agree to
            maintain any and all copyright or other proprietary notices
            contained in such Content, and to cite the URL Source of such
            Content. Reproduction of multiple copies of the Content, in whole or
            in part, for resale or distribution is strictly prohibited except
            with the prior written permission of Inkblot. To obtain written
            consent for such reproduction, please contact us at
            support@inkblottherapy.com.
          </p>
        </div>
        <div className="title">
          <p>14. Third Party Websites</p>
        </div>
        <div className="content-block">
          <p>
            The Platform may provide links to third party websites. Inkblot does
            not endorse the information contained on those websites or guarantee
            their quality, accuracy, reliability, completeness, currency,
            timeliness, non-infringement, merchantability or fitness for any
            purpose. The content in any linked web site is not under Inkblot’s
            control, and if you choose to access any such web site, you do so
            entirely at your own risk. By accessing and using the Platform, the
            Services and the Content, you expressly release and discharge us
            from any and all liability arising from your use of any third party
            website.
          </p>
        </div>
        <div className="title">
          <p>15. Viruses</p>
        </div>
        <div className="content-block">
          <p>
            You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the Internet or the Platform
            will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints
            to satisfy your particular requirements for anti-virus protection
            and accuracy of data input and output, and for maintaining a means
            external to our site for any reconstruction of any lost data.
            <br />
            <br /> We will not be liable for any loss or damage caused by a
            distributed denial-of-service attack, virus, or other
            technologically harmful material that may infect your computer
            equipment, mobile device, computer programs, data, or other
            proprietary material due to your use of the Platform or any services
            obtained through the Platform or to your downloading of any material
            posted on the Platform, or any website linked to it.
            <br />
            <br /> Your use of the Platform, its content and any services
            obtained through the Platform is at your own risk. The Platform, its
            content and any services obtained through the Platform are provided
            by Inkblot on an &quot;as is&quot; and &quot;as available&quot;
            basis, without any warranties of any kind, either express or
            implied. Neither Inkblot nor any person associated with Inkblot
            makes any warranties, guarantees or representations with respect to
            the completeness, security, reliability, timeliness, quality,
            suitability, accuracy or availability of the Platform, the Services
            and the Content.
          </p>
        </div>
        <div className="title">
          <p>16. Communications Not Confidential or Secure</p>
        </div>
        <div className="content-block">
          <p>
            Inkblot does not guarantee the confidentiality of any communications
            made by you through the Platform and Services. Although Inkblot
            generally adheres to accepted industry practices in securing the
            transmission of data to, from and through the Platform, you
            understand, agree and acknowledge that Inkblot cannot and does not
            guarantee the security of data transmitted over the Internet or
            public networks in connection with your use of the Platform.
            <br />
            <br /> You are responsible for ensuring that all persons who access
            Inkblot through your Wi-Fi or internet connection are aware of these
            Terms of Use, and that they comply with them. In the event that
            someone accesses the Platform, the Services and the Content using
            your User Account information, we will rely on that information and
            will assume that it is really you or your representative who is
            accessing the Platform. You are solely responsible for any and all
            use of your User Account information and all activities that arise
            in connection with the User Account. Without limiting any rights
            which we may otherwise have, we reserve the right to take any and
            all action, as deemed necessary or reasonable, to ensure the
            security of our Platform and your User Account, including, without
            limitation, terminating your User Account, changing your password,
            or requesting additional information to authorize transactions on
            your User Account. You agree to be responsible for any act or
            omission of any users that access the Platform, the Services and the
            Content under your User Account information that, if undertaken by
            you, would be deemed a violation of these Terms of Use. In no event
            and under no circumstances will Inkblot be held liable to you for
            any liabilities or damages resulting from or arising out of: (i) any
            action or inaction of Inkblot under this provision; (ii) any
            compromise of the confidentiality of your User Account or password;
            or (iii) any unauthorized access to your User Account or use of your
            password. You may not use another’s User Account at any time,
            without the permission of the User Account holder. Please contact us
            immediately if you suspect or become aware of any unauthorized use
            of your user name, password or User Account or any other breach of
            security.
          </p>
        </div>
        <div className="title">
          <p>17. Disclaimer and Limitation of Liability</p>
        </div>
        <div className="content-block">
          <p>
            THE LAWS OF CERTAIN JURISDICTIONS, INCLUDING QUEBEC, DO NOT ALLOW
            THE EXCLUSION OR LIMITATION OF LEGAL WARRANTIES, LIABILITY OR
            CERTAIN DAMAGES OR LIMITATIONS OF REPRESENTATIONS MADE CONCERNING
            GOODS OR SERVICES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
            BELOW EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU.
            <br />
            <br /> ALTHOUGH WE STRIVE TO UPDATE AND KEEP ACCURATE AS MUCH AS
            POSSIBLE THE CONTENT CONTAINED ON THE platform, ERRORS AND/OR
            OMISSIONS MAY OCCUR. ACCORDINGLY, THE PLATFORM AND THE CONTENT ARE
            PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR CONDITION OF ANY
            KIND. USE OF THE PLATFORM OR THE CONTENT IS entirely AT YOUR OWN
            RISK. INKBLOT DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR
            CONDITIONS ABOUT THE QUALITY, ACCURACY, RELIABILITY, COMPLETENESS,
            CURRENCY, OR TIMELINESS OF THE PLATFORM, THE CONTENT or the services
            (including, for greater certainty, any MENTAL HEALTH services
            provided by counsellors via the platform). THE PLATFORM, THE CONTENT
            or the services (including, for greater certainty, any MENTAL HEALTH
            services provided by counsellors via the platform) MAY INCLUDE
            ERRORS, OMISSIONS AND INACCURACIES, INCLUDING, WITHOUT LIMITAtiON,
            PRICING ERRORS. INKBLOT DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY
            ERRORS, OMISSIONS OR INACCURACIES IN THE PLATFORM, THE CONTENT or
            the services (including, for greater certainty, any MENTAL HEALTH
            services provided by counsellors via the platform). IN ADDITION,
            INKBLOT EXPRESSLY RESERVES THE RIGHT TO CORRECT ANY PRICING ERRORS
            ON THE PLATFORM. INKBLOT MAKES NO GUARANTEES ABOUT THE AVAILABILITY
            OF SPECIFIC SERVICES.
            <br />
            <br /> TO THE FULLEST EXTENT PERMITTED BY LAW, INKBLOT DISCLAIMS ALL
            WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY KIND WITH RESPECT
            TO THE PLATFORM, THE CONTENT or the services (including, for greater
            certainty, any MENTAL HEALTH services provided by counsellors via
            the platform), WHETHER EXPRESS, IMPLIED, STATUTORY OR COLLATERAL,
            INCLUDING ANY IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
            MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE OR
            NON-INFRINGEMENT, THAT ARISE OUT OF A COURSE OF DEALING OR TRADE, OR
            THAT THE PLATFORM, THE CONTENT or the services (including, for
            greater certainty, any MENTAL HEALTH services provided by
            counsellors via the platform) ARE OR WILL BE ERROR-FREE OR WILL
            OPERATE WITHOUT INTERRUPTION. IN NO EVENT WILL INKBLOT BE LIABLE,
            WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT
            LIABILITY OR ANY OTHER LEGAL THEORY, FOR ANY DAMAGES OF ANY KIND
            (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL,
            CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, LOST PROFITS,
            LOSS OF USE, LOSS OF DATA, PERSONAL INJURY, FINES, FEES, PENALTIES
            OR OTHER LIABILITIES), WHETHER OR NOT INKBLOT IS ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, RESULTING FROM OR RELATED TO THE USE
            OF, OR THE INABILITY TO MAKE USE OF, THE PLATFORM, THE CONTENT or
            the services (including, for greater certainty, any MENTAL HEALTH
            services provided by counsellors via the platform).
            <br />
            <br /> You acknowledge and agree that Inkblot is not licensed to
            provide treatment or other medical services. You acknowledge and
            agree that Inkblot is not providing any services beyond connecting
            you with a Counsellor through the Platform and is not providing you
            any medical advice or diagnosis. The Platform and/or the Services
            provided through the Platform and Counsellors is not a substitute
            for medical advice or treatment, including face-to-face counselling.
            You acknowledges and agree that the Counsellors using the Platform
            are solely responsible for and will have complete authority,
            responsibility, supervision, and control over the provision of all
            counselling services, advice, instructions, treatment decisions, and
            other professional mental health care services performed, and that
            all other professional mental health care services will be provided
            and performed exclusively by or under the supervision of
            Counsellors, in their sole discretion, as they deem appropriate. Any
            dispute between you and a Counsellor is strictly between you and
            such Counsellor, and you agree and acknowledge that Inkblot will not
            be accountable for any such disputes or other complaints.
            <br />
            <br /> You agree that while Inkblot does not monitor the Platform
            and has no responsibility to do so, Inkblot may use your IP address
            and contact police in situations where it determines in its sole
            discretion that your use of the Platform constitutes a significant
            risk of serious bodily harm to you or others. TO THE EXTENT THAT THE
            FOREGOING LIMITATION DOES NOT APPLY, IN NO EVENT WILL THE TOTAL
            AGGREGATE LIABILITY OF INKBLOT IN CONNECTION WITH OR UNDER THESE
            TERMS OF USE OR YOUR USE OF, OR INABILITY TO MAKE USE OF, THE
            PLATFORM, THE CONTENT or the services (including, for greater
            certainty, any MENTAL HEALTH services provided by counsellors via
            the platform), OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR USE
            OF, OR INABILITY TO MAKE USE OF, THE PLATFORM, THE CONTENT or the
            services (including, for greater certainty, any MENTAL HEALTH
            services provided by counsellors via the platform) EXCEED THE
            AGGREGATE AMOUNT OF THE FEES PAID BY YOU six (6) months immediately
            preceding such claim. FOR GREATER CERTAINTY, THE EXISTENCE OF ONE OR
            MORE CLAIMS UNDER THESE TERMS OF USE WILL NOT INCREASE THE MAXIMUM
            LIABILITY AMOUNT. The limitation above reflects the allocation of
            risk between the parties. The limitations specified in this section
            will survive and apply even if any limited remedy specified in these
            terms is found to have failed of its essential purpose.
            <br />
            <br /> Inkblot does not represent that it is covered by any
            insurance.
          </p>
        </div>
        <div className="title">
          <p>18. Indemnification</p>
        </div>
        <div className="content-block">
          <p>
            You covenant and agree to compensate, defend, and fully indemnify
            and hold harmless Inkblot, its parents, subsidiaries, affiliates,
            any related companies, its licensors and their respective officers,
            directors, employees, shareholders, members, attorneys, contractors,
            agents, licensors, and suppliers from and against any and all
            claims, legal proceedings, liabilities, damages, judgments, awards,
            losses, costs, and expenses or fees (including reasonable attorneys’
            fees and costs), including but not limited to those arising out of
            or in connection with:
            <br />
            <br /> Your violation or breach of any of these Terms of Use or your
            use of the Platform, including, without limitation, any use of
            Content and/or Services other than as expressly authorized in these
            Terms of Use or your use of any information obtained from the
            Platform; Your violation of any applicable law or the rights of a
            third party (including intellectual property rights);
            <br /> Any dispute that arises between you and any individual
            including, without limitation, a Counsellor;
            <br /> Any infringement by you in relation to your use of the
            Services under applicable law in your home nation or the country,
            state and/or city in which you are using, or have used, the Services
            or otherwise as a result of your fraud, negligence, mischief or
            willful misconduct;
            <br /> The actions, inactions, errors, omissions, representations,
            warranties, breaches or negligence of any Counsellors or Support
            Staff or for any personal injuries, death or other damages or
            expenses resulted therefrom;
            <br /> Indirect losses, which means loss to you which is a side
            effect of the main loss or damage and where you and Inkblot could
            not have reasonably anticipated that type of loss arising at the
            time of entering into these Terms of Use;
            <br /> Delay, cancellation or failure that is due to events beyond
            Inkblot’s control (e.g., a network failure, internet delays,
            rerouting, acts of any government or authority, acts of nature,
            telecommunication equipment failures, other equipment failures,
            electrical power failures, strikes, labour disputes, riots,
            insurrections, civil disturbances, shortages of labour or materials,
            fires, floods, storms, explosions, acts of God, war, governmental
            actions, orders of domestic or foreign courts or tribunals,
            non-performance of third parties, weather, or road conditions and
            breakdowns); and
            <br /> If for any reason, all or any part of the Platform is
            unavailable at any time or for any period. In particular, you agree
            to indemnify and hold harmless Inkblot, its affiliates, members,
            officers, employees, agents, sponsors, and licensors for any injury,
            including but not limited to bodily harm or death, to you or any
            third party that may result, directly or indirectly, from the use of
            or reliance on any Content, the Platform or the Services (including,
            for greater certainty, any services provided by Counsellors via the
            Platform).
            <br /> You further agree not to settle any such matter without
            Inkblot’s prior written consent.
          </p>
        </div>
        <div className="title">
          <p>19. Release</p>
        </div>
        <div className="content-block">
          <p>
            You hereby release Inkblot, its officers, employees, shareholders,
            agents and successors from any and all claims, demands, losses,
            damages, rights, claims, and actions of any kind including, without
            limitation, personal injuries, death, and property damage, that is
            either directly or indirectly related to or arises from any
            interactions with or conduct of any Inkblot users, Counsellors, or
            third-party websites.
          </p>
        </div>
        <div className="title">
          <p>20. Geographic Application of the Platform</p>
        </div>
        <div className="content-block">
          <p>
            Not all of the products and services described on the Platform are
            available in all jurisdictions. Furthermore, nothing on the Platform
            constitutes an offer or solicitation to buy or sell any product or
            service to anyone in any jurisdiction in which such an offer or
            solicitation is prohibited by law. Specifically, you acknowledge and
            agree that the products, services and/or material described on the
            Platform or the Services available through the Platform, are not
            appropriate or available for use in locations outside Canada, and
            may not function in all locations within Canada.
          </p>
        </div>
        <div className="title">
          <p>21. Governing Law and Jurisdiction</p>
        </div>
        <div className="content-block">
          <p>
            You hereby agree that (a) all aspects of the relationship between
            you and Inkblot (as well as its agents, delegates, employees, and
            any Counsellors or Support Staff), including without limitation any
            Services provided to you, and (b) the resolution of any and all
            disputes arising from or in connection with that relationship,
            including any disputes arising under or in connection with these
            Terms of Use, shall be governed by and construed in accordance with
            the laws of the Province of Ontario (other than conflict of laws
            rules) and the federal laws of Canada applicable therein, without
            regard to the choice or conflicts of law rules. Any disputes,
            actions, claims or causes of action arising out of or in connection
            with this Agreement or the Services shall be subject to the
            exclusive jurisdiction of the provincial and federal courts located
            in the Province of Ontario with the exception that Inkblot may seek
            relief in any court of competent jurisdiction to protect or enforce
            its intellectual property and propriety rights. You irrevocably
            consent to the jurisdiction of and venue in such courts (and of the
            appropriate appellate courts therefrom) and irrevocably waive, to
            the fullest extent permitted by law, any objection that you may have
            now or hereafter as to inconvenient forum.
            <br />
            <br /> These laws apply to your access to or use of the Platform,
            the Content and the Services, notwithstanding your domicile,
            residency or physical location. The Platform, the Content and the
            Services are intended for use only in jurisdictions where they may
            be lawfully offered for use. These Terms of Use shall not be
            governed by the United Nations Convention on Contracts for the
            International Sale of Goods, which is expressly excluded and you
            also expressly agree to exclude the applicability of the Uniform
            Computer Information Transactions Act. You agree that you will only
            sue us as an individual. You agree that you will not file a class
            action, or participate in a class action.
          </p>
        </div>
        <div className="title">
          <p>22. General</p>
        </div>
        <div className="content-block">
          <p>
            These Terms of Use (including the Privacy Policy incorporated by
            reference herein) constitute the entire agreement between Inkblot
            and you pertaining to the subject matter hereof and supersede all
            prior or contemporaneous communications and proposals, whether
            electronic, oral or written, between you and Inkblot with respect to
            the Platform, the Services and the Content. A printed version of
            these Terms of Use and of any notice given in electronic form will
            be admissible in judicial or administrative proceedings based upon
            or relating to these Terms of Use to the same extent and subject to
            the same conditions as other business documents and records
            originally generated and maintained in printed form.
            <br />
            <br /> Inkblot may wish to transfer our rights or obligations or
            sub-contract our obligations under these Terms of Use to another
            legal entity. You agree that we may do so provided that this will
            not adversely affect the standard or service you receive under these
            Terms of Use. As set out in Section 7 of these Terms of Use, you may
            terminate your agreement with us at any time. These Terms of Use are
            personal to you. You may not transfer your rights or obligations
            under these Terms of Use to anyone else.
            <br />
            <br /> If you breach these Terms and we take no action against you,
            we will still be entitled to use our rights and remedies in any
            situation where you breach these Terms of Use. The failure of
            Inkblot to enforce any right or provision in these Terms of Use
            shall not constitute a waiver of such right or provision.
            <br />
            <br /> You acknowledge that we have the right hereunder to seek an
            injunction, if necessary, to stop or prevent a breach of your
            obligations hereunder.
            <br />
            <br /> If any of the provisions contained in these Terms of Use are
            determined to be void, invalid or otherwise unenforceable by a court
            of competent jurisdiction, such determination will not affect the
            remaining provisions contained herein.
            <br />
            <br /> Headings are inserted for convenience of reference only and
            will not affect the construction or interpretation of these Terms of
            Use. The word &quot;including&quot; or &quot;includes&quot; means
            &quot;including (or includes) without limitation&quot;.
            <br />
            <br /> These Terms are not intended to give rights to anyone except
            you and us.
            <br />
            <br /> We are constantly looking for ways to improve and expand the
            Services. We may, in our sole discretion, amend or modify these
            Terms of Use from time to time. It is your responsibility to check
            these
            <br />
            <br /> Terms of Use, as they are binding on you. Your continued use
            of the Platform following the posting of changes to these Terms of
            Use constitutes acceptance of any changes. You can terminate at any
            time as set out above.
            <br />
            <br /> No joint venture, partnership, employment or agency
            relationship exists between you, the Company or any third party
            provider as a result of this Agreement or use of the Services.
            <br />
            <br /> You agree that it is the express wish of the parties that
            these Terms of Use and all related documents be drawn up in English.
            Vous êtes d&apos;accord que c’est la volonté expresse des parties
            que la présente convention ainsi que les documents qui s’y
            rattachent soient rédigés en anglais.
          </p>
        </div>
      </SectionContainer>
    </div>
  </Layout>
);

Conditions.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Conditions;
